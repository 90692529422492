import { useMemo, useRef } from 'react';
import dynamic from 'next/dynamic';
import useTranslation from 'next-translate/useTranslation';

import { AdPlacement, ModuleScheduleSection, PlatformID } from '@common/clients/api';
import { Ad } from '@web/molecules/Ad';
import { Experiment, RunningExperiments } from '@web/molecules/Experiment';
import { HeadlinesDossier } from '@web/molecules/HeadlinesDossier';
import { DisplayType, NewsList } from '@web/molecules/NewsList';
import { MatchBlock } from '@web/organisms/MatchBlock';
import { VideoCarousel as OnScrollVideoCarousel } from '@web/organisms/VideoCarousel/OnScrollLoadedVideoCarousel';
import { NewsListInsert } from '@web/templates/HomePage/organisms';

import { shouldInclude } from '../utils';
import { Props } from './HomeNewsList';

const OddsDossierIntro = dynamic(() =>
    import('@web/molecules/OddsDossierIntro').then((module) => module.OddsDossierIntro),
);

type Injections = Array<JSX.Element | undefined>;
type InjectionType = {
    injections: Injections;
};

/**
 * Create a ref array of <Ad/> and other injections for use in the NewsList.
 *
 * @example
 * injectedItems[increment.current] = <HeadlinesDossier
 *     dossier={headlinesDossier}
 *     />;
 *
 * @info `useRef` is used to prevent re-rendering when increment is updated
 * */
export const useInjections = (platform: PlatformID, props: Partial<Props>): InjectionType => {
    const { headlinesDossier, oddsDossier, videonews } = props;
    const __meta = useTranslation('meta').t;

    /** Ref to increment injected index **/
    const increment = useRef<number>(0);

    const injections = useMemo(() => {
        const injectedItems: Injections = [];

        increment.current = 0;

        if (videonews && shouldInclude(platform, NewsListInsert.LATEST_VIDEO_ARTICLES)) {
            increment.current += 5;
            const metaLatestVideosTitle = __meta(`${platform}-homepage.latest-videos-title`);
            injectedItems[increment.current] = (
                <Experiment
                    type={RunningExperiments.VIDEOS_ON_HOME}
                    A={() => (
                        <NewsList
                            title={<h2>{metaLatestVideosTitle}</h2>}
                            items={videonews}
                            moreArticlesLink={__meta('url:videos')}
                            displayType={DisplayType.carrousel}
                            displayTypeSwitchOptions={[]}
                            isHighlighted={true}
                            showIconTabs={false}
                        />
                    )}
                />
            );
        }

        if (oddsDossier && shouldInclude(platform, NewsListInsert.ODDS_DOSSIER)) {
            increment.current += 5;
            if (oddsDossier.tag) oddsDossier.tag.title = '';
            if (platform === PlatformID.VI && oddsDossier.matches) {
                const match = oddsDossier.matches[0];
                const bookmaker = oddsDossier?.items?.length ? oddsDossier.items[0]?.bookmaker : undefined;

                injectedItems[increment.current] = (
                    <MatchBlock
                        trackerName={ModuleScheduleSection.ODDS_DOSSIER_HOME}
                        match={match}
                        bookmaker={bookmaker}
                    />
                );
            } else {
                injectedItems[increment.current] = (
                    <OddsDossierIntro
                        trackerName={ModuleScheduleSection.ODDS_DOSSIER_HOME}
                        {...oddsDossier}
                    />
                );
            }
        }

        if (headlinesDossier && shouldInclude(platform, NewsListInsert.HEADLINES_DOSSIER)) {
            increment.current += 5;
            injectedItems[increment.current] = <HeadlinesDossier {...headlinesDossier} />;
        }

        if (shouldInclude(platform, NewsListInsert.VIDEO_PLAYER)) {
            increment.current += 5;
            if (platform === PlatformID.BR) {
                injectedItems[increment.current] = (
                    <Experiment
                        type={RunningExperiments.VIDEOS_ON_HOME}
                        B={() => (
                            <>
                                <OnScrollVideoCarousel />
                                <Ad placement={AdPlacement.HOME_AFTER_PLAYER} isOptional />
                            </>
                        )}
                    />
                );
            } else {
                injectedItems[increment.current] = (
                    <>
                        <OnScrollVideoCarousel />
                        <Ad placement={AdPlacement.HOME_AFTER_PLAYER} isOptional />
                    </>
                );
            }

            increment.current += 5;
            injectedItems[increment.current] = (
                <Ad placement={AdPlacement.HOME_AFTER_PLAYER_AFTER_ARTICLE_5} />
            );
        }

        const placements = [];
        placements[10] = AdPlacement.HOME_AFTER_ARTICLE_10;
        placements[40] = AdPlacement.HOME_AFTER_ARTICLE_40;
        placements.forEach((placement, index) => {
            injectedItems[index] = injectedItems[index] ? (
                <>
                    <Ad placement={placement} />
                    {injectedItems[index]}
                </>
            ) : (
                <Ad placement={placement} />
            );
        });

        return injectedItems;
    }, [videonews, platform, oddsDossier, headlinesDossier, __meta]);

    return { injections };
};
