import { FC, ReactElement, useMemo } from 'react';

import styles from './FeaturedCarrousel.module.scss';

interface Props {
    isDisabled?: boolean;
    onClick: () => void;
    children: ReactElement;
}

const ActionButtonIcon: FC<Props> = ({ isDisabled, onClick, children }) => {
    const buttonClassName = useMemo(
        () =>
            isDisabled
                ? `${styles['default-button-icon']} ${styles['disabled-button-icon']}`
                : styles['default-button-icon'],
        [isDisabled],
    );

    return (
        <div className={buttonClassName} onClick={onClick}>
            {children}
        </div>
    );
};

export default ActionButtonIcon;
