import { FC } from 'react';

import { PlatformID, Tag, TagTypeAsString } from '@common/clients/api';
import { ThumbnailWidth } from '@common/types/WebpWidth';
import { useContextData } from '@common/useContextData';

import { WebpImage } from '../WebpImage';

import styles from './TagImage.module.scss';

export interface Props {
    tag?: Tag;
    className?: string;
}

const getFallbackSrc = (platformID: string, isClub: boolean): string => {
    switch (platformID) {
        case PlatformID.VP:
        case PlatformID.VN:
            return `/images/${platformID}/${isClub ? 'club-placeholder.png' : 'placeholder.jpg'}`;
        case PlatformID.VI:
            return `https://vi.cdn.pxr.nl/.imaging/mte/vinl-website-theme/96/dam/assets/site-elements/logo_placeholder.png/jcr:content/logo_placeholder.png`;
        default:
            return `/images/${platformID}/placeholder.jpg`;
    }
};

export const TagImage: FC<Props> = ({ tag, className }) => {
    const contextData = useContextData();
    const { id: platformID } = contextData.platform;

    return (
        <span className={[styles['TagImage'], className].join(' ')}>
            <WebpImage
                defaultSize={ThumbnailWidth.WIDTH_110}
                src={tag?.thumbnail}
                fallbackSrc={getFallbackSrc(
                    platformID,
                    tag?.tagType === undefined || tag?.tagType === TagTypeAsString.SOCCER_CLUB,
                )}
                alt={tag?.title}
                ratio={1}
            />
        </span>
    );
};
