import { ComponentProps } from 'react';
import dynamic from 'next/dynamic';

import type { VideoCarousel as VideoCarouselType } from './VideoCarousel';

export const VideoCarousel = dynamic<ComponentProps<typeof VideoCarouselType>>(
    () => import('./VideoCarousel').then((mod) => mod.VideoCarousel),
    { ssr: false },
);

VideoCarousel.displayName = 'ClientSideVideoCarousel';
