import { PlatformID } from '@common/clients/api';
import { WidgetType } from '@web/organisms/Sidebar/types';

export enum NewsListInsert {
    HEADLINES_DOSSIER = 'headlines-dossier',
    ODDS_DOSSIER = 'odds-dossier-intro',
    UPCOMING_GP = 'upcoming-gp',
    LATEST_VIDEO_ARTICLES = 'latest-video-articles',
    VIDEO_PLAYER = 'video-player',
}

export type CombinedNewsListInsert = NewsListInsert | WidgetType;
export type PlatformIncludeInserts = Record<PlatformID, Array<CombinedNewsListInsert>>;
