import { FC } from 'react';

import { Match, Team } from '@common/clients/api';
import { InternalLink } from '@web/atoms/InternalLink';
import { TagImage } from '@web/atoms/TagImage';
import { Route } from '@web/routing';

import styles from './MatchThumbnail.module.scss';

interface Props {
    classes?: string;
    team: Team;
    match: Match;
}

export const MatchThumbnail: FC<Props> = ({ classes, team, match }) => {
    const shortName = team?.shortName || team.tag?.shortTitle;
    const label = shortName
        ? shortName
        : team.name
              .replace(/[^\p{L}\d]/gu, '')
              .toUpperCase()
              .substring(0, 3);

    const tagSlug = team.tag?.tagSlug;
    const route = tagSlug ? Route.Club : Route.Match;
    const query = tagSlug ? { tag: tagSlug } : { id: match.id };

    return (
        <div className={`${styles.MatchThumbnail} ${classes || ''}`}>
            <InternalLink route={route} query={query}>
                <TagImage className={styles.logo} tag={team?.tag} />
                <h4 className={'inverted'}>{label}</h4>
            </InternalLink>
        </div>
    );
};
