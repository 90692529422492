import { FC } from 'react';

import { ExperimentGroupOrRobots, PlatformID } from '@common/clients/api';
import { useContextData } from '@common/useContextData';

import { Pixel } from './atoms/Pixel';

export enum RunningExperiments {
    ARTICLE_IMAGE = 'article_image_above_vs_below_title',
    VIDEOS_ON_HOME = 'latest_videos_or_video_carousel_on_home',
}

type PlatformExceptions = {
    [key in PlatformID]?: ExperimentGroupOrRobots.A | ExperimentGroupOrRobots.B;
};

export interface Props<T> extends PlatformExceptions {
    type: RunningExperiments;
    A?: FC<T> | null;
    B?: ExperimentGroupOrRobots.A | FC<T> | null;
    Robots?: ExperimentGroupOrRobots.A | ExperimentGroupOrRobots.B | FC<T> | null;
    /**
     * `platformExceptions` can be used to create exceptions for certain platforms
     * These exceptions will not be tracked
     * @example { [PlatformID.VP]: ExperimentGroupOrRobots.A }
     */
    platformExceptions?: PlatformExceptions;
    props?: T;
}

export type Experiment<T = {}> = FC<Props<T>>;
export const Experiment: Experiment = ({ type, A, B, Robots = A, props, platformExceptions }) => {
    let PickedComponent: FC | string | null = null;

    const {
        experimentGroup,
        platform: { id: PlatformID },
    } = useContextData();

    if (platformExceptions?.[PlatformID] === ExperimentGroupOrRobots.A) {
        if (A) return <A />;
        return null;
    } else if (platformExceptions?.[PlatformID] === ExperimentGroupOrRobots.B) {
        if (B) return <B />;
        return null;
    }

    switch (experimentGroup) {
        case undefined:
        case ExperimentGroupOrRobots.A:
            if (A) PickedComponent = A;
            break;
        case ExperimentGroupOrRobots.B:
            if (B === ExperimentGroupOrRobots.A) {
                if (A) PickedComponent = A;
            } else if (B) {
                PickedComponent = B;
            }
            break;
        case ExperimentGroupOrRobots.ROBOTS:
            if (Robots === ExperimentGroupOrRobots.A) {
                if (A) PickedComponent = A;
            } else if (Robots === ExperimentGroupOrRobots.B) {
                if (B) PickedComponent = B;
            } else if (Robots) {
                PickedComponent = Robots;
            }
            break;
    }

    if (PickedComponent) {
        return (
            <>
                <Pixel type={type} />
                <PickedComponent {...props} />
            </>
        );
    }
    return null;
};
