import { ComponentProps } from 'react';
import dynamic from 'next/dynamic';

import { PlatformID } from '@common/clients/api';
import { useContextData } from '@common/useContextData';

import type { MatchTickerLoader as MatchTickerLoader } from './MatchTickerLoader';

const MatchTickerImport = dynamic<ComponentProps<typeof MatchTickerLoader>>(
    () => import('./MatchTickerLoader').then((mod) => mod.MatchTickerLoader),
    { ssr: false },
);

const MatchTickerPlatformFilter = (props: ComponentProps<typeof MatchTickerLoader>) => {
    const { platform } = useContextData();

    if ([PlatformID.GP, PlatformID.BR].includes(platform.id)) {
        return false;
    }
    return <MatchTickerImport {...props} />;
};

export const MatchTicker = dynamic<ComponentProps<typeof MatchTickerLoader>>(
    async () => MatchTickerPlatformFilter,
    {
        ssr: false,
    },
);
