import { PlatformID } from '@common/clients/api';
import { DisplayType, getDisplayTypeSwitchOptionsByPlatform } from '@web/molecules/NewsList';

export const isToday = (dateString: string): boolean => {
    const today = new Date();
    const date = new Date(dateString);
    return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
    );
};

export const getDisplayConfig = (platform: PlatformID) => {
    switch (platform) {
        case PlatformID.VI:
            return {
                showIconTabs: false,
                skipDivider: true,
                displayType: DisplayType.blocks,
                mobileDisplayType: DisplayType.list,
            };
        default:
            return {
                showIconTabs: true,
                displayTypeSwitchOptions: getDisplayTypeSwitchOptionsByPlatform(platform),
            };
    }
};
