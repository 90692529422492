import { FC } from 'react';

import { Link } from '@common/atoms/Link';
import { ThreeWayOdds } from '@common/clients/api';

import styles from './BettingPill.module.scss';

interface Props {
    classes?: string;
    threeWayOdds: ThreeWayOdds;
    oddSymbol: '1' | 'X' | '2' | string;
    oddValue: string;
}

// Caution: An error was being generated here:
// Failed prop type: The prop `href` expects a `string` or `object` in `<Link>`, but got `undefined` instead.
// Open your browser's console to view the Component stack trace.

export const BettingPill: FC<Props> = ({ classes = '', threeWayOdds, oddSymbol, oddValue }) => (
    <li className={`${styles.BettingPill} ${classes} small`}>
        <Link href={threeWayOdds.url}>
            <a href={threeWayOdds.url} rel="sponsored nofollow" target="_blank">
                <span className={styles['threeway-symbol']}>{oddSymbol}</span>
                <span className={styles.odd}>{oddValue}</span>
            </a>
        </Link>
    </li>
);
